







import { Component, Vue } from "vue-property-decorator";
import Erfassung from "@/components/Erfassung.vue"; // @ is an alias to /src
import gql from "graphql-tag";

@Component({
  components: {
    Erfassung,
  },
  apollo: {
    upsertSeed: {
      query: gql`
        query BetriebsdatenByPk($buchung: Int!) {
          betriebsdaten_by_pk(buchung_id: $buchung) {
            buchung_id
            auftrag_id
            arbeitsplatz_id
            prozess_id
            vorgang_id
            datum_start
            datum_ende
            bemerkung
            auftrag_volumen_verarbeitet
            auftrag_volumen
            arbeitsplatz {
              maschine_id
            }
            attribut_werts {
              attribut_id
              wert
            }
          }
        }
      `,
      update: (data) => data?.betriebsdaten_by_pk,
      fetchPolicy: "no-cache",
      variables() {
        return {
          buchung: this.$route.params.buchung_id ?? -1,
        };
      },
    },
  },
})
export default class Update extends Vue {
  upsertSeed = null;
}
